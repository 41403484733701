import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import { TbTruckDelivery } from "react-icons/tb";
import { FaWalking } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import Footer from "../components/footer"

export default function OrderOption() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove('dark');
    document.documentElement.classList.add('light');
  }, []);

  return (
    <>
      <Navbar />

      <section className="relative md:pb-24 pb-16 bg-black">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-blue md:mt-24 mt-16">Selecciona una opción para recibir su orden</h3>
          </div>

          <div className="container relative md:mt-24 mt-16">
            <div className="lg:flex justify-center">
              <div className="lg:w-4/5">
                <ul className="md:flex inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-black border dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                  <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                    <Link to="/upload-image" className="py-2 px-5 inline-flex flex-col items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-blue border-amber-400 hover:border-amber-500 text-white rounded">
                      <span className="mb-1"><TbTruckDelivery className="text-4xl" /></span>
                      <span>Delivery</span>
                    </Link>
                  </li>

                  {/* Agregar un espacio entre los botones */}
                  <div className="md:w-1/12 w-full"></div>

                  <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                    <Link to="/booths" className="py-2 px-5 inline-flex flex-col items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-pink border-amber-400 hover:border-amber-500 text-white rounded">
                      <span className="mb-1"><FaWalking className="text-4xl" /></span>
                      <span>Recoger</span>
                    </Link>
                  </li>

                  {/* Agregar un espacio entre los botones */}
                  <div className="md:w-1/12 w-full"></div>

                  <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                    <Link to="/checkout-delivery" className="py-2 px-5 inline-flex flex-col items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-yellow-600 border-amber-400 hover:border-amber-500 text-white rounded">
                      <span className="mb-1"><IoTicketOutline className="text-4xl" /></span>
                      <span>Ticket</span>
                    </Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}
