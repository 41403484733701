import React, { useState, useEffect } from "react";
import profileImg from "../assets/images/registro.png";
import { useDispatch } from 'react-redux';
import { unSetUserToken } from '../features/authSlice';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { getToken, removeToken } from '../services/LocalStorageServices';
import { useGetLoggedUserQuery } from '../services/UserAuthApi';
import { unsetUserInfo } from '../features/userSlice';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
    const handleLogout = () => {
        dispatch(unsetUserInfo({ name: "", email: "", phone_number: "", identification: ""}))
        dispatch(unSetUserToken({ access_token: null }))
        removeToken()
        navigate('/login')
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { access_token } = getToken()
    const { data, isSuccess } = useGetLoggedUserQuery(access_token)

    const [userData, setUserData] = useState({
        email: "",
        name: "",
        phone_number: "",
        identification: ""
    })

    // Store User Data in Local State
    useEffect(() => {
        if (data && isSuccess) {
            setUserData({
                email: data.email,
                name: data.name,
                phone_number: data.phone_number,
                identification: data.identification
                
            })
        }
    }, [data, isSuccess])

    return (
        <>
            <Navbar />
            <section className="relative lg:py-24 py-16 bg-green2-gradient">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-5 md:col-span-6 flex justify-center md:justify-start">
                            <div className="lg:ms-5">
                                <div className="border bg-black dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold text-white">Tu información de perfil</h3>

                                    <div className="grid lg:grid-cols-2 lg:gap-6">
                                        <div className="lg:col-span-12 text-white">
                                            <p><strong>Nombre:</strong> {userData.name}</p>
                                            <p><strong>Correo:</strong> {userData.email}</p>
                                            <p><strong>Phone:</strong> {userData.phone_number}</p>
                                            <p><strong>Cédula:</strong> {userData.identification}</p>
                                        </div>
                                    </div>

                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleLogout}
                                            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md"
                                        >
                                            Cerrar sesión
                                        </button>
                                    </div>
                                    </div>
                                    <div className="border bg-black dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold text-white">Cambiar contraseña</h3>

                                    <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="password" className="font-semibold text-white">Contraseña:</label>
                                            <input name="password" id="password" type="text" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Contraseña :"/>
                                        </div>
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="password2" className="font-semibold text-white">Repite la contraseña:</label>
                                            <input name="password2" id="password2" type="text" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Contraseña :"/>
                                        </div>

                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleLogout}
                                            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md"
                                        >
                                            Actualizar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={profileImg} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
