import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import Pricing from "../components/pricing"
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import ColoredLine from "../components/colorLine";

export default function Pricings(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.remove('dark');
        document.documentElement.classList.add('light');
      }, []);

    return(
        <>
        <Navbar/>
        <section className="relative mt-16 bg-black bg-no-repeat bg-bottom bg-cover">
            <div className="absolute inset-0"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 text-center mt-6">
                    <div>
                        <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Precios de nuestros productos</h5>
                    </div>

                    <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Fotomax</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white mb-8" aria-current="page">Productos</li>
                    </ul>
                </div>
            </div>
        </section>
        <ColoredLine />

        <section className="relative md:pb-24 pb-16 bg-black">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mt-17 text-blue">No tiene que elegir entre costo, tiempo y calidad</h3>

                    <p className=" max-w-xl mx-auto text-white">Explora sin compromisos y encuentra la perfecta armonía entre asequibilidad, eficiencia y excelencia en nuestra amplia selección de productos. </p>
                </div>
                <Pricing/>
            </div>
        </section>
        <ColoredLine />
        <Footer/>
        </>
    )
}