import React from "react";
import conveniosImg from "../assets/images/Ariel.png";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import ColoredLine from "../components/colorLine";

export default function Convenios() {

    return(
        <>
        <Navbar/>
        <section className="relative lg:py-24 py-16 bg-pink-gradient">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-black rounded-md shadow p-6">
                                <h3 className="mb-6 text-3xl leading-normal font-semibold text-white text-center">¡Convenios a tu medida!</h3>
                                <p className="text-slate-400 max-w-xl mx-auto text-white text-justify">Realizamos diferentes convenios, alianzas, promociones y alternativas publicitarias para que nuestros clientes vivan la mejor experiencia posible. Un ejemplo de esto son los convenios con clubs de fans en la que nuestros clientes pueden personalziar sus tiras fotograficas con sus artistas favoritos tanto en nuestras cabinas como de forma online.</p>
                                <br/>
                                <button
                                type=""
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-black border-black  rounded-md"
                                >
                                </button>
                                <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md">Contáctanos</Link>
                                <Link to="https://webconvenios-4af7c.web.app/signup" className="mx-1 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue text-white rounded-md">Registrate</Link>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={conveniosImg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
        <ColoredLine />
        <Footer/>
        </>
    )
}