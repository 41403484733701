import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Importa Link desde react-router-dom
import successImg from "../assets/images/sucess.png";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useGetBoothsQuery } from "../services/BoothsApi";

const SuccessPayment = () => {
  // Obtener datos de cabinas desde el endpoint
  const handlePageChange = (page) => {
    if (page > 0) {
      setCurrentPage(page);
      // Mantener los filtros al cambiar de página
      refetch();
    }
  }; 
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
  const [searchTerm, setSearchTerm] = useState('');
  const { data: getbooths, error, isLoading, refetch } = useGetBoothsQuery({ page: currentPage, search: searchTerm }); // Incluye el parámetro de búsqueda en la consulta
  const [isNextEnabled, setIsNextEnabled] = useState(true);
  
  useEffect(() => {
    // Verificar si hay más páginas disponibles
    setIsNextEnabled(getbooths && getbooths.next !== null);
  }, [getbooths]);

    return (
        <>
            <Navbar />
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={successImg} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-black border dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">¡Orden realizada correctamente!</h3>
                                    <p className="mb-6 text-lg text-gray-700">¡Gracias por confiar en nosotros para tus compras en línea! Esperamos que disfrutes de tus productos, te hemos enviado un email con mas información sobre la orden. Puedes elegir cualqueira de nuestras ubicaciones disponibles para recoger tu pedido.</p>
                                    {/* Barra de búsqueda */}
                                    <input
                                      type="text"
                                      placeholder="Buscar ciudad..."
                                      value={searchTerm}
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                      className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
                                    />
                                    {/* Mostrar datos de cabinas */}
                                    {isLoading ? (
                                    <p>Cargando cabinas...</p>
                                  ) : error ? (
                                    <p>Ocurrió un error al cargar las cabinas.</p>
                                  ) : (
                                    <div>
                                      {getbooths && getbooths.results.length > 0 ? (
                                        <>
                                          {getbooths.results.map((booth, index) => {
                                            return (
                                              <div key={index} className="mb-4 flex items-center">
                                                <div className="nombre-duracion-container">
                                                  <p><strong>Ubicacion:</strong> {booth.location}</p>
                                                  <p><strong>Ciudad:</strong> {booth.city}</p>
                                                </div>
                                                {index !== getbooths.results.length - 1 && <hr className="my-2" />}
                                              </div>
                                            );
                                          })}
                                          <div className="pagination text-black" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                            <button
                                              style={{
                                                margin: '0 5px',
                                                padding: '5px 10px',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => handlePageChange(currentPage - 1)}
                                              disabled={currentPage === 1} // Deshabilitar el botón "Anterior" en la primera página
                                            >
                                              Anterior
                                            </button>
                                            <button
                                              style={{
                                                margin: '0 5px',
                                                padding: '5px 10px',
                                                backgroundColor: '#eee',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => handlePageChange(currentPage + 1)}
                                              disabled={!isNextEnabled} // Deshabilitar el botón "Siguiente" si no hay más páginas
                                            >
                                              Siguiente
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <p className="text-white">No se encontraron cabinas</p>
                                      )}
                                    </div>
                                  )}
                                  <div className="text-center">
                                  <Link to="/" className="mt-10 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md">
                                  Ir al inicio
                                  </Link>
                                    </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SuccessPayment;
