import React, { useState, useEffect } from "react";
import contactImg from "../assets/images/payment.png";
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { usePostmakepaymentMutation } from '../services/MercadopagoApi';
import StatusBrick from '../components/StatusBrick';
import { useNavigate } from 'react-router-dom';
import { useGetTotalPaymentQuery } from '../services/MercadopagoApi';

const CheckoutDelivery = () => {
    const [postmakepayment, { isLoading, isError, error }] = usePostmakepaymentMutation();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const navigate = useNavigate();
    const { data: totalPayment, error: paymentError, isLoading: paymentLoading, refetch: getTotalPayment } = useGetTotalPaymentQuery();
    const [paymentRejected, setPaymentRejected] = useState(false);

    useEffect(() => {
        initMercadoPago(`APP_USR-34ef0e39-ca55-4de5-b62f-85e4eb745e03`);
        getTotalPayment(); // Realizar la llamada inicial para obtener el total de pago
    }, [getTotalPayment]);

    const initialization = {
        amount: totalPayment ? totalPayment.total_amount : 0,
    };    

    const onSubmit = async (formData) => {
        try {
            const result = await postmakepayment(formData);
            if (result.error) {
                console.error("Error al procesar el pago:", result.error);
            } else {
                console.log("Payment Successful");
                console.log(result);
                setPaymentSuccess(true);
            }
        } catch (error) {
            console.error("Error al procesar el pago:", error);
        }
    };

    const onError = async (error) => {
        console.error("Error en el pago:", error);
    
        if (error.response && error.response.result) {
            console.log("Detalles adicionales del error:", error.response.result);
            // Puedes acceder a detalles específicos del error, como error.response.result.id, error.response.result.message, etc.
        } else {
            console.log("No se pudieron obtener detalles adicionales del error.");
        }
    };    

    const onReady = async () => {
        // Callback llamado cuando Brick está listo.
        // Aquí puedes ocultar cargamentos de tu sitio, por ejemplo.
    };

    useEffect(() => {
        if (paymentSuccess) {
            navigate('/success-payment');
        }
    }, [paymentSuccess, navigate]);

    return (
        <>
            <Navbar />
            <section className="relative lg:py-24 py-16 bg-other-gradient">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contactImg} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                                <div className="bg-black border rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold text-center text-white">¡Ultimo paso para ordenar!</h3>
                                    {totalPayment && (
                                    <div>
                                        <h3 className="mb-6 text-2xl leading-normal text-center font-semibold text-white">Total a pagar: ${totalPayment.total_amount}</h3>
                                    </div>
                                )}
                                    
                                    <CardPayment
                                        initialization={initialization}
                                        onSubmit={onSubmit}
                                        onReady={onReady}
                                        onError={onError}
                                    />
                                    {paymentSuccess && <StatusBrick />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CheckoutDelivery;
