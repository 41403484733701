import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../services/LocalStorageServices';

// Función para obtener el token de acceso
const getAccessToken = () => {
  const { access_token } = getToken();
  return access_token ? `Bearer ${access_token}` : '';
};

export const addPhotoApi = createApi({
    reducerPath: 'addPhotoApi',
    baseQuery: fetchBaseQuery({ 
      baseUrl: 'https://backend-fotomax.onrender.com/api/v1/photo/',
      prepareHeaders: (headers) => {
        // Incluye el token de acceso en el encabezado de autorización
        const token = getAccessToken();
        if (token) {
          headers.set('Authorization', token);
        }
        return headers;
      }
    }),
    endpoints: (builder) => ({
      addPhoto: builder.mutation({
        query: (formData) => ({
          url: `upload-photo/`,
          method: 'POST',
          body: formData,
        }),
      }),
    }),
  });
  

export const { useAddPhotoMutation } = addPhotoApi;
