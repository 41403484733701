import React, { useState, useEffect } from "react";
import contactImg from "../assets/images/contact.svg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { usePostContactMutation } from "../services/ContactApi";
import ColoredLine from "../components/colorLine";

export default function Contact() {
  const [serverError, setServerError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [postContact, { isLoading }] = usePostContactMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar si los campos están vacíos
    for (const key in contactFormData) {
      if (!contactFormData[key]) {
        setServerError({ message: 'Por favor, completa todos los campos.' });
        return;
      }
    }

    const res = await postContact(contactFormData);

    if (res.error) {
      setServerError(res.error.data.errors);
    }
    if (res.data) {
        // Limpiar los campos
        setContactFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
    }
    // Mostrar el mensaje de éxito
    setSuccessMessage("Mensaje enviado correctamente.");

    // Puedes manejar la respuesta exitosa aquí si es necesario
    console.log("Mensaje enviado exitosamente:", res.data);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  }, []);


    return(
        <>
        <Navbar/>
        <section className="relative lg:py-24 py-16 bg-yellow-gradient">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-black rounded-md shadow p-6">
                                <h3 className="mb-6 text-3xl leading-normal font-semibold text-white text-center">¡Ponte en contacto con nosotros!</h3>

                                <form onSubmit={handleSubmit}>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="name" className="font-semibold text-white">Nombre:</label>
                                            <input name="name" id="name" type="text" value={contactFormData.name} onChange={handleInputChange} className="bg-white form-input w-full py-2 px-3 h-10 bg-transparent rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Nombre :"/>
                                        </div>
        
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="email" className="font-semibold text-white">Correo electrónico:</label>
                                            <input name="email" id="email" type="email" value={contactFormData.email} onChange={handleInputChange} className="bg-white form-input w-full py-2 px-3 h-10 bg-transparent rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Correo electrónico :"/>
                                        </div>
                                    </div>
    
                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <label htmlFor="subject" className="font-semibold text-white">Asunto:</label>
                                            <input name="subject" id="subject" value={contactFormData.subject} onChange={handleInputChange} className="bg-white form-input w-full py-2 px-3 h-10 bg-transparent rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Asunto :"/>
                                        </div>
    
                                        <div className="mb-5">
                                            <label htmlFor="comments" className="font-semibold text-white">Tu mensaje:</label>
                                            <textarea name="message" id="message" value={contactFormData.message} onChange={handleInputChange} className="bg-white form-input w-full py-2 px-3 bg-transparent rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2 h-40" placeholder="Mensaje :"></textarea>
                                        </div>
                                    </div>
                                    {serverError.message && <p className="text-red-600 text-center mb-4">{serverError.message}</p>}
                                    {successMessage && <p className="text-green-600 text-center mb-4">{successMessage}</p>}
                                    <button
                                type=""
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-black border-black  rounded-md"
                                >
                                Enviar Mensaje
                                </button>
                                    <button
                                type="submit"
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-pink hover:bg-pinki border-amber-400 hover:border-amber-500 text-white rounded-md"
                                >
                                Enviar Mensaje
                                </button>
                             </form>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={contactImg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
        <ColoredLine />
        <Footer/>
        </>
    )
}