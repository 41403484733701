import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userAuthApi } from './services/UserAuthApi';
import { contactApi} from './services/ContactApi';
import { productApi } from './services/ProductsApi';
import { cartApi } from './services/CartApi';
import authReducer from './features/authSlice';
import userReducer from './features/userSlice';
import { boothsApi } from './services/BoothsApi';
import { makepaymentApi } from './services/MercadopagoApi';
import { updateAddressApi } from './services/AddressApi';
import { addPhotoApi } from './services/PhotoApi';

export const store = configureStore({
  reducer: {
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [boothsApi.reducerPath]: boothsApi.reducer,
    [makepaymentApi.reducerPath]: makepaymentApi.reducer,
    [updateAddressApi.reducerPath]: updateAddressApi.reducer,
    [addPhotoApi.reducerPath]: addPhotoApi.reducer,
    auth: authReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userAuthApi.middleware, productApi.middleware, contactApi.middleware, cartApi.middleware, boothsApi.middleware , makepaymentApi.middleware,
    updateAddressApi.middleware, addPhotoApi.middleware),
});

setupListeners(store.dispatch);
