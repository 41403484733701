import React from "react";
import about1 from '../assets/images/features/video-1.png'
import about2 from '../assets/images/features/video-2.png'
import about3 from '../assets/images/features/video-3.png'

export default function Features({classlist}){
    const featuresData = [
        {
            image: about1,
            title: "+40 ubicaciones disponibles.",
            desc: "Ahora puedes ver todas nuestras ubicaciones en tiempo real y la disponibilidad de cada una de ellas.",
            color: "text-blue" // Color para el primer título
        },
        {
            image: about2,
            title: "Regala tus mejores momentos",
            desc: "A la hora de pagar tu compra en línea puedes escribir el correo de la persona a quien quieres hacer el regalo para que reciba un código con el que pueda recoger las fotografías en su cabina más cercana.",
            color: "text-pink" // Color para el segundo título
        },
        {
            image: about3,
            title: "Tus fotografías a tu casa",
            desc: "Ahora te ofrecemos la opción de llevarte tus fotografías hasta la comodidad de tu hogar.",
            color: "text-yellow-600" // Color para el tercer título
        },
    ]
    return(
        <>
        <div className="relative bg-black">
            <div className={classlist}>
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mt-6 mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-blue">Nos actualizamos para<br/> <span className="bg-gradient-to-br text-pink">facilitarte tu vida diaria. Cabinas Fotomax</span></h3>

                    <p className="text-white max-w-xl mx-auto">Ahora puedes imprimir tus fotografías desde donde estés y recoger en cualquiera de nuestras ubicaciones disponibles.</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featuresData.map((item,index) => {
                        return(
                        <div className="relative overflow-hidden dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800" key={index}>
                            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full">
                                <img src={item.image} className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1" alt=""/>
                            </div>

                            <div className="p-6">
                                <h5 className={`text-lg font-semibold text-center ${item.color}`}>{item.title}</h5>
                                <p className="text-white mt-3 text-center">{item.desc}</p>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            </div>
        </>
    )
}
