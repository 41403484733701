import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../services/LocalStorageServices';

// Función para obtener el token de acceso
const getAccessToken = () => {
  const { access_token } = getToken();
  return access_token ? `Bearer ${access_token}` : '';
};

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://backend-fotomax.onrender.com/api/v1/cart/',
    prepareHeaders: (headers) => {
      // Incluye el token de acceso en el encabezado de autorización
      const token = getAccessToken();
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    postCart: builder.mutation({
      query: (formData) => ({
        url: 'add-item/',
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
    getCart: builder.query({
      query: () => ({
        url: 'cart-items/',
        method: 'GET',
      }),
    }),
    getitemsCart: builder.query({
      query: () => ({
        url: 'get-item-total/',
        method: 'GET',
      }),
    }),
    getallitemsCart: builder.query({
      query: () => ({
        url: 'cart-items/',
        method: 'GET',
      }),
    }),
    gettotalCart: builder.query({
      query: () => ({
        url: 'get-total/',
        method: 'GET',
      }),
    }),
    deleteItem: builder.mutation({
      query: (formData) => ({
        url: 'remove-item/',
        method: 'DELETE',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
    updateItem: builder.mutation({
      query: (formData) => ({
        url: 'update-item/',
        method: 'PUT',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
  }),
});

export const { usePostCartMutation, useGetCartQuery, useGetitemsCartQuery, useGetallitemsCartQuery, useGettotalCartQuery, useDeleteItemMutation, useUpdateItemMutation } = cartApi;
