import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import conveniosImg from "../assets/images/Ariel.png";
import AboutImg from "../assets/images/about.jpg"
import image1 from "../assets/images/1.png"
import image2 from "../assets/images/2.png"
import image3 from "../assets/images/3.png"
import Footer from "../components/footer";
import Navbar from "../components/navbar";

import {FiCheckCircle} from "../assets/icons/vander"

import ColoredLine from "../components/colorLine";
import '../../node_modules/react-modal-video/css/modal-video.css'


export default function AboutUs(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.remove('dark');
        document.documentElement.classList.add('light');
      }, []);
    const [isOpen, setOpen] = useState(false);
    const [activeIndex,setActiveIndex] = useState(0)
    return(
        <>
        <Navbar/>
        <section className="relative mt-16 bg-black">
            <div className="container relative">
                <div className="grid grid-cols-1 text-center">
                    <div>
                        <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Sobre Nosotros</h5>
                    </div>

                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Fotomax</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white mb-8 " aria-current="page">Sobre Nosotros</li>
                    </ul>
                </div>
            </div>
        </section>
        <ColoredLine />

        <section className="relative md:py-24 py-16 bg-black">

            <div className="container relative mt-12">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:m-auto after:w-96  after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-black dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800 lg:me-6">
                        <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                            <img src={AboutImg} alt=""/>
                        </div>
                    </div>
                    <div className="">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-white text-center"><span className="font-bold">¿Que hacemos?</span> </h3>
                        <p className="text-slate-400 max-w-xl text-white text-justify">FotoMax ofrece cabinas fotográficas líderes en el mercado. Nuestra misión es crear la mejor experiencia para nuestros consumidores. Por esta razón nuestros productos contienen la última tecnología disponible en el mercado y ofrecen una amplia gama de formatos de fotografías configurables.​​ ﻿Nuestras cabinas disponen de una variedad de alternativas, entre ellas más de 20 estilos de fotografías disponibles todos incluidos en nuestros servicios. Nuestros productos poseen un​ software amigable e interactivo en diferentes lenguajes.</p>

                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Software moderno</li>
                            <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Sistema rápido y optimizado</li>
                            <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Seguridad</li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/contact" className="text-white font-medium duration-500">Más información <i className="mdi mdi-chevron-right text-[20px] align-middle"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <ColoredLine />

            <div className="container relative md:mt-10">
                <div className="lg:flex justify-center">
                    <div className="lg:w-4/5">
                        <ul className="md:flex inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-black dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                            <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                <button className={`${activeIndex === 0 ? "text-black bg-blue" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 text-white`} onClick={()=>setActiveIndex(0)}>
                                    <h5 className="text-base font-semibold">+50 diseños</h5>
                                    <p className="text-sm mt-1">Contamos con variados estilos fotográficos para lograr que cada consumidor tenga una experiencia única. </p>
                                </button>
                            </li>
                            <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                <button className={`${activeIndex === 1 ? "text-black bg-pink" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 text-white`} onClick={()=>setActiveIndex(1)}>
                                    <h5 className="text-base font-semibold">Diseños Exclusivos</h5>
                                    <p className="text-sm mt-1">Trabajamos con cada cliente con el objetivo de crear una cabina fotográfica que tenga la identidad de marca y el mensaje que se desea comunicar.</p>
                                </button>
                            </li>
                            <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                <button className={`${activeIndex === 2 ? "text-black bg-yellow" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 text-white `} onClick={()=>setActiveIndex(2)} >
                                    <h5 className="text-base font-semibold">Experiencia del consumidor</h5>
                                    <p className="text-sm mt-1">Nuestras cabinas fotográficas cuentan con constante soporte y tiempos de respuesta expeditos de manera de asegurar un servicio de calidad.</p>
                                </button>
                            </li>
                        </ul>
    
                        <div id="StarterContent" className="mt-6 mb-6">
                            {activeIndex === 0 ? 
                                <div>
                                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-blue px-6 pt-6">
                                        <img src={image1} className="rounded-t-lg" alt=""/>
                                    </div>
                                </div> :''
                            }
                            {activeIndex === 1 ? 
                                <div>
                                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-pink px-6 pt-6">
                                        <img src={image2} className="rounded-t-lg" alt=""/>
                                    </div>
                                </div> :''
                            }
                            {activeIndex === 2 ? 
                            <div>
                                <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-yellow px-6 pt-6">
                                    <img src={image3} className="rounded-t-lg" alt=""/>
                                </div>
                            </div>:''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ColoredLine/>
            <section className="relative py-16 bg-pink">
            <div className="container">
                <div className="lg:col-span-7 md:col-span-6">
                    <img src={conveniosImg} alt="" style={{ width: '900px', height: 'auto' }} />
                </div>
            </div>
        </section>
        <ColoredLine/>
        </section>
        <Footer/>
        </>
    )
}