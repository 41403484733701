import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cartImg from "../assets/images/carrito.svg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useGetallitemsCartQuery, useDeleteItemMutation, useUpdateItemMutation } from "../services/CartApi";
import { TrashIcon } from '@heroicons/react/16/solid';

const Cart = () => {
  const [productos, setProductos] = useState([]);
  const { data: cartItems = [], isLoading, refetch } = useGetallitemsCartQuery();
  const [deleteItem, { isLoading: deleteLoading }] = useDeleteItemMutation();
  const [updateItem, { isLoading: updateLoading }] = useUpdateItemMutation();

  useEffect(() => {
    refetch(); // Llamar a la función refetch para actualizar los datos del carrito al montar el componente
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setProductos(cartItems.cart || []);
    }
  }, [isLoading, cartItems]);

  const aumentarCantidad = async (id) => {
    const updatedProduct = productos.find(producto => producto.product.id === id);
    if (updatedProduct) {
      await updateItem({ product_id: updatedProduct.product.id, count: updatedProduct.count + 1 });
      refetch(); // Actualizar el carrito después de actualizar el producto
    }
  };
  
  const disminuirCantidad = async (id) => {
    const updatedProduct = productos.find(producto => producto.product.id === id);
    if (updatedProduct && updatedProduct.count > 1) {
      await updateItem({ product_id: updatedProduct.product.id, count: updatedProduct.count - 1 });
      refetch(); // Actualizar el carrito después de actualizar el producto
    }
  };  

  const calcularTotal = () => {
    return productos.reduce((total, producto) => total + producto.count * producto.product.price, 0);
  };

  const handleDeleteItem = async (id) => {
    try {
      await deleteItem({ product_id: id });
      refetch(); // Actualizar el carrito después de eliminar el producto
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  return (
    <>
      <Navbar />
      <section className="relative lg:py-24 py-16 bg-orange-gradient">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-black dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold text-center text-white">Carrito de compras</h3>

                  {isLoading ? (
                    <p className="text-white">Cargando...</p>
                  ) : productos.length === 0 ? (
                    <p className="text-white">No hay productos en el carrito.</p>
                  ) : (
                    productos.map((producto) => (
                      <div key={producto.id} className="flex items-center justify-between border-b py-2">
                        <div className="flex items-center">
                          <img
                            src={producto.product.image}
                            alt={producto.product.name}
                            className="w-12 h-12 rounded-full mr-4"
                            onError={(e) => {
                              e.target.src = "https://via.placeholder.com/150";
                            }}
                          />
                          <div>
                            <p className="font-bold text-white">{producto.product.name}</p>
                            <p className="font-bold text-white">Precio: ${producto.product.price}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <button
                            className="bg-green-600 text-white px-2 py-1 rounded"
                            onClick={() => disminuirCantidad(producto.product.id)}
                          >
                            -
                          </button>
                          <span className="mx-2 text-white">{producto.count}</span>
                          <button
                            className="bg-green-600 text-white px-2 py-1 rounded"
                            onClick={() => aumentarCantidad(producto.product.id)}
                          >
                            +
                          </button>
                          <button
                            className="text-red-500 hover:text-red-600 trash-icon"
                            onClick={() => handleDeleteItem(producto.product.id)}
                          >
                            <TrashIcon className="w-4 h-4 text-white" />
                          </button>
                        </div>
                      </div>
                    ))
                  )}

                  {productos.length > 0 && (
                    <div className="mt-4 flex justify-between items-center">
                      <p className="font-bold text-white">Total a Pagar: ${calcularTotal()}</p>
                      <Link
                        to="/orderoption"
                        className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-green-600 hover:border-amber-500 text-white rounded-md"
                      >
                        Comprar
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6">
              <img src={cartImg} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cart;
