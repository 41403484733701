import React, { useRef, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { useAddPhotoMutation } from '../services/PhotoApi'; 
import { useNavigate } from 'react-router-dom'; 
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const UploadImage = () => {
    const fileInputRef = useRef(null);
    const cropperRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null); // Nuevo estado para el error
    const [addPhoto, { isLoading }] = useAddPhotoMutation();
    const navigate = useNavigate(); 
    
    const handlePhotoClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        setError(null); // Reiniciar el error cuando se seleccionan archivos
    };    

    const removeSelectedImage = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
        setError(null); // Reiniciar el error cuando se seleccionan archivos
        handleCropperInitialized(cropperRef.current); // Actualizar la referencia del cropper
    };
    
    
    const handleNextStep = async () => {
        if (selectedFiles.length === 0) {
            setError("Debes seleccionar al menos una imagen.");
            return;
        }
 
        try {
            const formData = new FormData();
    
            // Procesar cada imagen seleccionada
            await Promise.all(selectedFiles.map(async (file, index) => {
                // Obtener el recorte de la imagen
                const croppedImage = cropperRef.current.getCroppedCanvas({
                    width: 200, // Cambiar al tamaño de píxeles deseado
                    height: 200,
                });
    
                // Convertir la imagen recortada a un archivo Blob
                return new Promise((resolve, reject) => {
                    croppedImage.toBlob((blob) => {
                        // Agregar la imagen recortada al formData
                        formData.append(`croppedImage`, blob, `croppedImage${index}.jpg`);
                        // Agregar la imagen original al formData
                        formData.append(`image`, file, `image${index}.jpg`);
                        resolve();
                    });
                });
            }));
    
            // Realizar la llamada a la API para enviar las imágenes
            addPhoto(formData);
    
            navigate('/select-address');
        } catch (error) {
            console.error("Error al enviar la foto:", error);
        }
    };        

    const handleCropperInitialized = (cropper) => {
        cropperRef.current = cropper;
    };

    return (
        <>
            <Navbar />
            <section className="relative lg:py-24 py-16 bg-black">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                        </div>
                    </div>
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-black border dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <h3 className="mb-6 text-2xl leading-normal text-center font-semibold text-blue">Previsualiza y edita las imagenes</h3>
                                <p className="pb-6 text-center text-lg max-w-xl mx-auto text-white">Se te cobrará en base al producto seleccionado en tu carrito y la cantidad de fotos seleccionadas.</p>
                                <div className="flex items-center mb-6">
                                    <button className=" flex items-center" onClick={handlePhotoClick}>
                                        <p className="mx-1 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center border-amber-400 hover:border-amber-500 text-white rounded-mdpy-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-blue text-white rounded-md">Añadir fotos</p>
                                    </button>
                                    <input type="file" accept="image/*" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileSelect} multiple />
                                
                                    <button
                                        type="button"
                                        className={`py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        onClick={handleNextStep}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Enviando..." : "Siguiente paso"}
                                    </button>
                                </div>
                                {error && <p className="text-red-600 text-sm">{error}</p>} {/* Mostrar el error si existe */}
                                <div className="grid grid-cols-4 gap-6">
                                    {selectedFiles.map((file, index) => (
                                        <div key={index} className="relative mb-4 mx-1">
                                            <button className="absolute top-2 right-2 z-10" onClick={() => removeSelectedImage(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 5.293a1 1 0 011.414 0L10 10.586l4.293-4.293a1 1 0 111.414 1.414L11.414 12l4.293 4.293a1 1 0 01-1.414 1.414L10 13.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 12 4.293 7.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                            <Cropper
                                                src={URL.createObjectURL(file)}
                                                style={{ height: '100%', width: '100%' }}
                                                aspectRatio={4/3}
                                                guides={true}
                                                ref={cropperRef}
                                                onInitialized={handleCropperInitialized}
                                                zoomable={true}
                                                viewMode={3}
                                                responsive={false}
                                                cropBoxMovable={true}
                                                cropBoxResizable={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default UploadImage;
