import React from "react";
import conveniosImg from "../assets/images/manos.png";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import ColoredLine from "../components/colorLine";

export default function Bussines() {

    return(
        <>
        <Navbar/>
        <section className="relative lg:py-24 py-16 bg-blue-gradient">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-black rounded-md shadow p-6">
                                <h3 className="mb-6 text-3xl leading-normal font-semibold text-white text-center">¡Somos tu mejor socio!</h3>
                                <p className="text-slate-400 max-w-xl mx-auto text-white text-justify">Fotomax es la compañía líder en el mercado de fotocabinas en Chile, hace más de 10 años que estamos presentes en los malls más importantes a lo largo de todo el territorio nacional.
                                    Hemos atendido a más de 3.000.000 de clientes de todos los segmentos etarios, sociales y culturales.
                                    Nos hemos transformado en un referente a la hora de plasmar las diferentes experiencias que vivimos con nuestras parejas, amigos, familiares y seres queridos en todos los malls y cines de nuestro país.
                                    Nuestra misión es crear la mejor experiencia para nuestros consumidores. Por esta razón nuestras cabinas contienen la última tecnología disponible en el mercado, ofrecen una amplia gama de diseños de fotografías configurables para personas y promocionales para empresas.
                                    Nuestras cabinas disponen de una gran variedad de alternativas, entre ellas más de 27 estilos de fotografías disponibles, todos incluidos en nuestros servicios.
                                    Además poseen un software amigable e interactivo en diferentes lenguajes.</p>
                                    <p className="text-slate-400 max-w-xl mx-auto text-white">Te invitamos a vivir y a recordar la experiencia!</p>
                                <br/>
                                <button
                                type=""
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-black border-black  rounded-md"
                                >
                                </button>
                                <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md">Contáctanos</Link>
                                <button
                                type=""
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-black border-black  rounded-md"
                                >
                                </button>
                                <Link to="https://webconvenios-4af7c.web.app/signup" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue text-white rounded-md">Registrate</Link>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={conveniosImg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
        <ColoredLine />
        <Footer/>
        </>
    )
}
