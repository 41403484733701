import React from 'react';

const ColoredLine = () => {
  return (
    <div className="line-container">
    <div className="line blue"></div>
      <div className="line pink"></div>
      <div className="line yellow"></div>
    </div>
  );
};

export default ColoredLine;
