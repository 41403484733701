import React,{useState} from "react";
import { Link } from "react-router-dom";
import videoImg from "../assets/images/modern.mp4"

import {FiCheckCircle} from '../assets/icons/vander'

import '../../node_modules/react-modal-video/css/modal-video.css'

export default function AboutThree(){

    return(
        <>
    <div className="relative bg-black">
        <div className="container relative bg-black">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative overflow-hidden border-2 rounded-lg mt-4 mb-8">
                        <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                            <div className="relative">
                                <video controls autoPlay loop>
                                    <source src={videoImg} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    
                    </div>
                    <div className="">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-white text-center">Cómo usar nuestras cabinas <br/> fotomax</h3>
                    <p className="text-slate-400 max-w-xl text-white">"Mejora la experiencia de tus consumidores! Una interactiva cabina de fotos para tu local comercial. Encuéntranos en los mejores cines y malls del país."</p>

                    <ul className="list-none text-slate-400 mt-4">
                        <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Interfaz moderna</li>
                        <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Fácil de usar</li>
                        <li className="mb-2 flex items-center text-white"><FiCheckCircle className="text-white h-5 w-5 me-2"/> Seguridad</li>
                    </ul>

                    <div className="mt-4">
                        <Link to="/aboutus" className="hover:text-amber-400 font-medium duration-500 text-white">Más información <i className="mdi mdi-chevron-right text-[20px] align-middle "></i></Link>
                    </div>
                    </div>
                    </div>
                </div>
            </div>

        </>
    )
}