import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/logo-icon-64.png';
import ColoredLine from "../components/colorLine";
import Mano from "../assets/images/Mano.png";

const RegisterOption = () => {
  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-black bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center items-center">
            <div className="lg:w-2/4 md:w-2/4">
              <div className="rounded shadow bg-black border p-6">
                <Link to="/">
                  <img src={logo} alt="" className="mx-auto mb-4" />
                </Link>

                <h5 className="mt-6 text-register text-yellow text-center">Regístrate aqui</h5>
                <h5 className="mt-6 text-register text-transparent text-center">regístrate aqui</h5>

                <div className="flex justify-between mt-6">
                  <div className="text-center">
                    <h5 className="text-register2 text-white">Regístrate como empresa</h5>
                    <h5 className="text-register2 text-transparent">regístrate como empresa</h5>
                    <Link to="https://webconvenios-4af7c.web.app/signup" className="py-2 px-6 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-pink border-pink text-white rounded-md mt-4">Siguiente</Link>
                  </div>
                  <div className="text-center">
                    <h5 className="text-register2 text-white">Regístrate como persona</h5>
                    <h5 className="text-register2 text-transparent">regístrate como empresa</h5>
                    <Link to="/signup" className="py-2 px-6 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-pink border-pink text-white rounded-md mt-4">Siguiente</Link>
                  </div>
                </div> 
              </div>
            </div>

            {/* Imagen de la mano posicionada a la derecha */}
            <div className="lg:w-2/4 md:w-2/4 flex justify-center md:justify-end mt-6 md:mt-0 image-register">
              <img src={Mano} alt="Mano" className="max-w-full h-auto" />
            </div>
          </div>
        </div>
      </section>
      <ColoredLine/>
    </>
  );
}

export default RegisterOption;
