import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/images/logo-icon-64.png';

const ErrorBooths = () => {

  return (
    <>
      <section className="relative overflow-hidden min-m-screen flex items-center bg-black bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-1/3 md:w-2/4">
              <div className="rounded shadow border bg-black dark:bg-slate-900 p-6 mt-6">
                <Link to="/">
                <img src={logo} alt="" className="mx-auto mb-4" />
                </Link>

                <h5 className="mt-6 text-xl font-semibold text-blue">Descargar imagenes de la cabinas</h5>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold text-white" htmlFor="LoginEmail">Ingresa el código de error:</label>
                      <input id="email" type="email" className="form-input mt-3 w-full py-2 px-3 h-10 bg-white dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0" placeholder="Ingresa el codigo"/>
                    </div>
                    <div className="mb-4">
                      <input type="submit" className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md w-full" value="Descargar"/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ErrorBooths;