import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../services/LocalStorageServices';

// Función para obtener el token de acceso
const getAccessToken = () => {
    const { access_token } = getToken();
    return access_token ? `Bearer ${access_token}` : '';
  };

export const makepaymentApi = createApi({
  reducerPath: 'makepaymentApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://backend-fotomax.onrender.com/api/v1/payments',
    prepareHeaders: (headers) => {
        // Incluye el token de acceso en el encabezado de autorización
        const token = getAccessToken();
        if (token) {
          headers.set('Authorization', token);
        }
        return headers;
      }
}),
  endpoints: (builder) => ({
    postmakepayment: builder.mutation({
      query: (formData) => ({
        url: 'make-payment/',
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
    getTotalPayment: builder.query({
      query: () => ({
        url: 'get-payment-total',
        method: 'GET',
      }),
    }),
  }),
});

export const { usePostmakepaymentMutation, useGetTotalPaymentQuery } = makepaymentApi;
