import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoLight from '../assets/images/logo-light.png'

import { FiFacebook, FiInstagram, FiTwitter } from '../assets/icons/vander'
import { FaGlobe } from 'react-icons/fa';
import ColoredLine from "./colorLine";

export default function Footer() {
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

    const toggleLanguageMenu = () => {
        setLanguageMenuOpen(!languageMenuOpen);
    };

    return (
        <>
            <div className="relative">
            </div>
            <footer className="relative bg-gray-900 overflow-hidden bg-black">
                <span className="absolute blur-[200px] w-[300px] h-[300px] rounded-full top-0 -start-[0] bg-gradient-to-tl z-0"></span>
                <div className="container-fluid relative mt-6">
                    <div className="container relative">
                        <div className="grid grid-cols-1 text-center">
                            <div className="">
                                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl text-white tracking-normal mb- text-blue">Visita nuestra sección de productos</h4>
                                <p className="text-white/70 text-lg max-w-xl mx-auto">Visita nuestra sección de productos y elige la opción que más se adapte a tus necesidades.</p>

                                <div className="mt-6">
                                    <Link to="/pricing" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-pink border-pink text-white rounded-md">Compra Ahora</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative text-center">
                    <div className="grid grid-cols-1 border-t border-gray-800 dark:border-slate-800">
                        <div className="py-[30px] px-0">
                            <div className="flex justify-center">
                                <ul className="list-none footer-list text-center mt-6 space-x-1">
                                    <li className="inline">
                                        <Link to="https://www.facebook.com/" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-full bg-blue text-slate-300 hover:text-white"><FiFacebook className="h-4 w-4 align-middle" /></Link>
                                    </li>
                                    <li className="inline">
                                        <Link to="https://www.instagram.com/fotomaxoficial/" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-full bg-blue text-slate-300 hover:text-white"><FiInstagram className="h-4 w-4 align-middle" /></Link>
                                    </li>
                                    <li className="inline">
                                        <Link to="https://twitter.com/" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-full bg-blue text-slate-300 hover:text-white"><FiTwitter className="h-4 w-4 align-middle" /></Link>
                                    </li>
                                    <li className="inline">
                                        <button onClick={toggleLanguageMenu} className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-full bg-blue text-slate-300 hover:text-white"><FaGlobe className="h-4 w-4 align-middle" /></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:text-start text-center flex justify-center">
                <Link to="#" className="text-[22px] focus:outline-none">
                    <img src={LogoLight} className="mx-auto md:me-auto md:ms-0" alt="" />
                </Link>
                </div>

                {languageMenuOpen && (
                    <div className="py-2 text-center">
                        <button className="py-2 px-4 mb-2 bg-gray-800 text-white rounded-md">Español</button>
                        <button className="py-2 px-4 mb-2 bg-gray-800 text-white rounded-md">English</button>
                    </div>
                )}

                <div className="py-[30px] px-0 border-t border-gray-800 dark:border-slate-800">
                    <div className="container relative text-center">
                        <div className="grid grid-cols-1">
                            <div className="text-center">
                                <p className="text-gray-400">© {new Date().getFullYear()} Fotomax. Creado por Sixteen Code.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ColoredLine />
        </>
    )
}
