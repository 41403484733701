import React, { useState, useEffect } from "react";
import deliveryImg from "../assets/images/ubicacion2.png";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { usePutAddressMutation, useGetAddressDetailsQuery } from '../services/AddressApi';
import { useNavigate } from 'react-router-dom';

const Address = () => {
    const { data: addressDetails, isSuccess } = useGetAddressDetailsQuery();
    const [formData, setFormData] = useState({
        address_line_1: '',
        city: '',
        state_province_region: '',
        postal_zip_code: '',
        telephone_number: ''
    });

    const navigate = useNavigate(); // Inicializa la función navigate

    useEffect(() => {
        if (isSuccess) {
            setFormData({
                address_line_1: addressDetails.address_line_1,
                city: addressDetails.city,
                state_province_region: addressDetails.state_province_region,
                postal_zip_code: addressDetails.postal_zip_code,
                telephone_number: addressDetails.telephone_number
            });
        }
    }, [isSuccess, addressDetails]);

    const [putAddress, { isLoading }] = usePutAddressMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validar que todos los campos estén llenos
        if (Object.values(formData).every(field => field !== '')) {
            putAddress({ formData })
                .unwrap()
                .then(() => {
                    // Manejar el éxito de la solicitud aquí
                    navigate('/checkout-delivery');
                })
                .catch((error) => {

                });
        } else {
            alert('Por favor, completa todos los campos.');
        }
    };

    return (
        <>
            <Navbar />
            <section className="relative lg:py-24 py-16 bg-blued-gradient">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={deliveryImg} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-black border dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold text-white">Completa tu dirección de envio</h3>

                                    <form onSubmit={handleSubmit}>
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="street_name" className="font-semibold text-white">Nombre de la calle:</label>
                                            <input name="address_line_1" id="address_line_1" type="text" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Dirección :" onChange={handleChange} value={formData.address_line_1} required />
                                        </div>

                                        <div className="mb-5">
                                            <label htmlFor="city_name" className="font-semibold text-white">Ciudad:</label>
                                            <input name="city" id="city" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Ciudad :" onChange={handleChange} value={formData.city} required />
                                        </div>
                                        <div className="mb-5">
                                            <label htmlFor="state_province_region" className="font-semibold text-white">Estado o provincia:</label>
                                            <input name="state_province_region" id="state_province_region" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Estado o provincia :" onChange={handleChange} value={formData.state_province_region} required />
                                        </div>
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="zip_code" className="font-semibold text-white">Código postal:</label>
                                            <input name="postal_zip_code" id="postal_zip_code" type="text" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Código postal :" onChange={handleChange} value={formData.postal_zip_code} required />
                                        </div>
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="telefono" className="font-semibold text-white">Télefono:</label>
                                            <input name="telephone_number" id="telephone_number" type="text" className="text-white form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Télefono :" onChange={handleChange} value={formData.telephone_number} required />
                                        </div>
                                        <button
                                            type="submit"
                                            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Cargando...' : 'Continuar'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Address;
