import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const boothsApi = createApi({
  reducerPath: 'boothsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://backend-fotomax.onrender.com/api/v1/booths/',
  }),
  endpoints: (builder) => ({
      getBooths: builder.query({
        query: ({ page, search }) => ({
          url: 'booths-list/',
          method: 'GET',
          params: { page, search }, // Incluye el parámetro "search" aquí
        }),
      }),
  }),
});

export const { useGetBoothsQuery } = boothsApi;
