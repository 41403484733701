import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Blog from './pages/blog';
import IndexLight from './pages/index-light';
import AboutUs from './pages/aboutus';
import Pricing from './pages/pricing';
import Services from './pages/services';
import BlogDetails from './pages/blog-detail';
import Helpcenter from './pages/helpcenter';
import Login from './pages/login';
import Signup from './pages/signup';
import ResetPassword from './pages/reset-password';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Error from './pages/error';
import Contact from './pages/contact';
import Dashboard from "./pages/dashboard"
import Cart from "./pages/cart"
import OrderOption from "./pages/order_option"
import { useSelector } from "react-redux";
import SuccessSendReset from './pages/reset-password-success';
import EnterResetPassword from './pages/enter-reset-password';
import SuccessRegister from './pages/success-register';
import NewPasswordSuccess from './pages/NewPasswordSuccess';
import CheckoutDelivery from './pages/checkout-delivery';
import Address from './pages/address_select';
import SuccessPayment from './pages/success-payment';
import UploadImage from './pages/upload_image';
import Booths from './pages/locations';
import Convenios from './pages/convenios';
import Bussines from "./pages/bussines"
import RegisterOption from "./pages/reigster_option";

function App() {
  const { access_token } = useSelector(state => state.auth)
  return (
    <Router>
    <Routes>
      <Route path='/' element={<IndexLight/>} />
      <Route path='/blog' element={<Blog/>} />
      <Route path='/aboutus' element={<AboutUs/>} />
      <Route path='/:id/:token' element={<EnterResetPassword />} />
      <Route path='/reset-password-success' element={<SuccessSendReset/>} />
      <Route path='/new-password-success' element={<NewPasswordSuccess/>} />
      <Route path='/success-register' element={<SuccessRegister/>} />
      <Route path='/pricing' element={<Pricing/>} />
      <Route path='/services' element={<Services/>} />
      <Route path='/blog-detail' element={<BlogDetails/>} />
      <Route path='/blog-detail/:id' element={<BlogDetails/>} />
      <Route path='/helpcenter' element={<Helpcenter/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/signup' element={<Signup/>} />
      <Route path='/reset-password' element={<ResetPassword/>} />
      <Route path='/terms' element={<Terms/>} />
      <Route path='/privacy' element={<Privacy/>} />
      <Route path='/error' element={<Error/>} />
      <Route path='*' element={<Error/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='orderoption' element={<OrderOption/>} />
      <Route path="/dashboard" element={access_token ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path='/cart' element={<Cart/>} />
      <Route path='/checkout-delivery' element={<CheckoutDelivery/>} />
      <Route path='/select-address' element={<Address/>} />
      <Route path='/success-payment' element={<SuccessPayment/>} />
      <Route path='/upload-image' element={<UploadImage/>} />
      <Route path='/booths' element={<Booths/>} />
      <Route path='/convenios' element={<Convenios/>} />
      <Route path='/empresa' element={<Bussines/>} />
      <Route path='/register-option' element={<RegisterOption/>} />

    </Routes>
    </Router>
  );
}

export default App;
