import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetBoothsQuery } from "../services/BoothsApi";
import locationImg from "../assets/images/location.png";
import Features from "../components/features";
import AboutThree from "../components/aboutThree";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import ErrorBooths from "../components/ErrorBooths";
import ColoredLine from "../components/colorLine";

export default function Services() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  }, []);

  const handlePageChange = (page) => {
    if (page > 0) {
      setCurrentPage(page);
      // Mantener los filtros al cambiar de página
      refetch();
    }
  }; 
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
  const [searchTerm, setSearchTerm] = useState('');
  const { data: getbooths, error, isLoading, refetch } = useGetBoothsQuery({ page: currentPage, search: searchTerm }); // Incluye el parámetro de búsqueda en la consulta
  const [isNextEnabled, setIsNextEnabled] = useState(true);

  useEffect(() => {
    // Verificar si hay más páginas disponibles
    setIsNextEnabled(getbooths && getbooths.next !== null);
  }, [getbooths]);

  return (
    <>
      <Navbar />
      <section className="relative mt-16 bg-black bg-bottom bg-cover">
        <div className="absolute inset-0"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                Ubicaciones / Cabinas
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Fotomax</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white mb-8"
                aria-current="page"
              >
                Cases
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="relative">
      <ColoredLine />
      </div>
      <section className="relative lg:py-24 py-16 bg-blue-gradient-2">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-black border dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                <h3 className="mb-6 text-2xl leading-normal font-semibold text-white text-center">Cabinas activas y ubicaciones</h3>
                  {/* Barra de búsqueda */}
                  <input
                    type="text"
                    placeholder="Buscar ciudad..."
                    className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 text-black"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* Mostrar datos de cabinas */}
                  {isLoading ? (
                  <p>Cargando cabinas...</p>
                ) : error ? (
                  <p>Ocurrió un error al cargar las cabinas.</p>
                ) : (
                  <div>
                    {getbooths && getbooths.results.length > 0 ? (
                      <>
                        {getbooths.results.map((booth, index) => {
                          return (
                            <div key={index} className="mb-4 flex items-center">
                              <div className="nombre-duracion-container text-white">
                                <p><strong>Ubicacion:</strong> {booth.location}</p>
                                <p><strong>Ciudad:</strong> {booth.city}</p>
                              </div>
                              {index !== getbooths.results.length - 1 && <hr className="my-2" />}
                            </div>
                          );
                        })}
                        <div className="pagination text-white" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                          <button
                            style={{
                              margin: '0 5px',
                              padding: '5px 10px',
                              backgroundColor: '#000000',
                              border: '2px solid #FFFFFF',
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1} // Deshabilitar el botón "Anterior" en la primera página
                          >
                            Anterior
                          </button>
                          <button
                            style={{
                              margin: '0 5px',
                              padding: '5px 10px',
                              backgroundColor: '#000000',
                              border: '2px solid #FFFFFF',
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={!isNextEnabled} // Deshabilitar el botón "Siguiente" si no hay más páginas
                          >
                            Siguiente
                          </button>
                        </div>
                      </>
                    ) : (
                      <p className="text-white">No se encontraron cabinas</p>
                    )}
                  </div>
                )}
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6 rounded-lg">
              <img src={locationImg} alt="" />
            </div>
          </div>
        </div>
      </section>
      <ColoredLine />
      <ErrorBooths />
      <ColoredLine />
      <Features classlist="container relative" />
      <ColoredLine />
      <AboutThree/>
      <ColoredLine />
      <Footer />
    </>
  );
}
