import React, { useState, useEffect } from "react";
import { FaShoppingCart } from 'react-icons/fa';
import { useGetProductsQuery } from '../services/ProductsApi';
import { usePostCartMutation, useGetCartQuery } from '../services/CartApi';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate();
  const { data: cartData } = useGetCartQuery();
  const { data, isLoading, error } = useGetProductsQuery();
  const [addToCart, { isLoading: isAddingToCart, error: addToCartError }] = usePostCartMutation();
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (cartData) {
      setCartItems(cartData.cart);
    }
  }, [cartData]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>Error loading products: {error.message}</p>;
  }
  
  if (!data || !data.data || !Array.isArray(data.data)) {
    return <p>No products available.</p>;
  }
  
  const products = data.data;

  const handleAddToCart = async (productId) => {
    try {
      if (!localStorage.getItem('access_token')) {
        navigate('/login'); // Redirige a la página de inicio de sesión si no hay token de acceso
        return;
      }

      await addToCart({ product_id: productId });
      navigate('/orderoption'); // Redirige a la página '/orderoption' después de agregar al carrito
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };      

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6 bg-black border">
      {products.map((product) => (
        <div key={product.id} className="relative overflow-hidden rounded-md shadow-lg dark:shadow-gray-800">
          <div className="p-6">
            <h5 className="text-2xl leading-normal font-semibold text-center text-white">{product.name}</h5>
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-32 object-cover mb-4 rounded-md "
              onError={(e) => {
                e.target.src = "https://via.placeholder.com/150";
              }}
            />
            <p className="text-slate-400 mt-2 text-white">{product.description}</p>
            <div className="flex mt-4 items-center">
              <span className="text-lg font-semibold text-white">$</span>
              <span className="text-3xl font-semibold mb-0 ms-1 text-white">{product.price}</span>
              <button
                className={`py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center ${
                  cartItems.some(item => item.product.id === product.id) ? 'bg-gray-600 text-gray-700 cursor-not-allowed' : 'bg-blue hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white'
                } rounded ms-4 `}
                onClick={() => handleAddToCart(product.id)}
                disabled={cartItems.some(item => item.product.id === product.id) || isAddingToCart}
              >
                <span className="me-2"><FaShoppingCart /></span>
                {cartItems.some(item => item.product.id === product.id) ? 'Agregado' : isAddingToCart ? 'Agregando...' : 'Comprar'}
              </button>
            </div>
            {addToCartError && addToCartError.status === 401 && (
              <p className="text-red-600 mt-2 ">Por favor, inicia sesión para agregar productos al carrito.</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pricing;
