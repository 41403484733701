import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../services/LocalStorageServices';

// Función para obtener el token de acceso
const getAccessToken = () => {
  const { access_token } = getToken();
  return access_token ? `Bearer ${access_token}` : '';
};

export const updateAddressApi = createApi({
  reducerPath: 'updateAddressApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://backend-fotomax.onrender.com/api/v1/address/',
    prepareHeaders: (headers) => {
      // Incluye el token de acceso en el encabezado de autorización
      const token = getAccessToken();
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    putAddress: builder.mutation({
      query: ({formData }) => ({
        url: `update-address/`,
        method: 'PUT',
        body: formData,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
    getAddressDetails: builder.query({
        query: () => ({
          url: 'detail-address/',
          method: 'GET',
        }),
      }),
  }),
});

export const { usePutAddressMutation, useGetAddressDetailsQuery } = updateAddressApi;
