import React, { useEffect } from "react";
import Slider from "react-slick";

import bannerImg from '../assets/images/classic01.png';
import bannerImg2 from '../assets/images/classic02.png';
import bannerImg3 from '../assets/images/classic03.png';
import bannerImg5 from '../assets/images/classic05.png';

import Navbar from "../components/navbar";
import Features from "../components/features";
import Faq from "../components/Faq";
import Footer from "../components/footer";
import ColoredLine from "../components/colorLine";

const images = [bannerImg, bannerImg2, bannerImg3, bannerImg5];

export default function IndexLight() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true
    };

    return (
        <>
            <Navbar />
            <section className="bg-black relative overflow-hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center">
                        <div className="relative mt-10 z-3">
                            <Slider {...settings}>
                                {images.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt={`banner-${index}`} className="max-w-xxl rounded-lg mx-auto" />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative bg-black">
                <Features classlist="container relative" />
                <ColoredLine />
            </section>
            <Faq />
            <ColoredLine />
            <Footer />
        </>
    );
}
