import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetBoothsQuery } from "../services/BoothsApi";
import locationImg from "../assets/images/ubicacion.png";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

export default function Booths() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  }, []);

  const handlePageChange = (page) => {
    if (page > 0) {
      setCurrentPage(page);
      // Mantener los filtros al cambiar de página
      refetch();
    }
  }; 
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const { data: getbooths, error, isLoading, refetch } = useGetBoothsQuery({ page: currentPage, search: searchTerm }); // Incluye el parámetro de búsqueda en la consulta
  const [isNextEnabled, setIsNextEnabled] = useState(true);

  useEffect(() => {
    // Verificar si hay más páginas disponibles
    setIsNextEnabled(getbooths && getbooths.next !== null);
  }, [getbooths]);

  return (
    <>
      <Navbar />
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative lg:py-24 py-16 bg-greend-gradient">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={locationImg} alt="" />
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-black border dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                <h3 className="mb-6 text-2xl leading-normal font-semibold text-black">Cabinas activas y ubicaciones</h3>
                  {/* Barra de búsqueda */}
                  <input
                    type="text"
                    placeholder="Buscar ciudad..."
                    className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 text-black"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Actualiza el estado de búsqueda al escribir en la barra
                  />
                  {/* Mostrar datos de cabinas */}
                  {isLoading ? (
                  <p>Cargando cabinas...</p>
                ) : error ? (
                  <p>Ocurrió un error al cargar las cabinas.</p>
                ) : (
                  <div>
                    {getbooths && getbooths.results.length > 0 ? (
                      <>
                        {getbooths.results.map((booth, index) => {
                          return (
                            <div key={index} className="mb-4 flex items-center text-white">
                              <div className="nombre-duracion-container">
                                <p><strong>Ubicacion:</strong> {booth.location}</p>
                                <p><strong>Ciudad:</strong> {booth.city}</p>
                              </div>
                              {index !== getbooths.results.length - 1 && <hr className="my-2" />}
                            </div>
                          );
                        })}
                        <div className="pagination" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                          <button
                            style={{
                              margin: '0 5px',
                              padding: '5px 10px',
                              backgroundColor: '#eee',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1} // Deshabilitar el botón "Anterior" en la primera página
                          >
                            Anterior
                          </button>
                          <button
                            style={{
                              margin: '0 5px',
                              padding: '5px 10px',
                              backgroundColor: '#eee',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={!isNextEnabled} // Deshabilitar el botón "Siguiente" si no hay más páginas
                          >
                            Siguiente
                          </button>
                        </div>
                      </>
                    ) : (
                      <p className="text-white">No se encontraron cabinas</p>
                    )}
                  </div>
                )}
                 <div className="text-center">
                <Link to="/upload-image" className="mt-10 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-pink text-white rounded-md">
                Continuar
                </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
